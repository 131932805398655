import { gql, useMutation } from '@apollo/client';
import { faAngleDown, faMinusCircle, faPhoneSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem, styled } from '@mui/material';
import { useAuthentication } from '@onemedical/auth';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import roles from '../data/roles';
import NavButton from './NavButton';
import NavMenu from './NavMenu';
import { InternalUser } from './models/InternalUser';
import { useProviderStatus } from './hooks/useProviderStatus';

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($roleIds: [ID!]!) {
    updateProfile(input: { roleIds: $roleIds }) {
      success
      errors
      profile {
        id
        roles {
          id
        }
      }
    }
  }
`;

const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '32px',
}));

const mapIdsToString = (ids: (number | undefined)[]) =>
  ids.filter((id): id is number => !!id).map((id) => id.toString());

interface UserMenuProps {
  profile: InternalUser;
  currentRoles?: {
    id: number;
  }[];
  passProviderStatusVisibility: Dispatch<SetStateAction<boolean>>;
  currentlyOut: boolean | null;
}

interface FrontDeskMenuItemProps {
  isFrontDeskAdmin: boolean;
  onDisable: () => void;
  onEnable: () => void;
}

function FrontDeskMenuItem({ isFrontDeskAdmin, onDisable, onEnable }: FrontDeskMenuItemProps) {
  if (isFrontDeskAdmin) {
    return (
      <MenuItem data-cy="front-desk-toggle" onClick={onDisable}>
        Disable Front Desk Mode
      </MenuItem>
    );
  }
  return (
    <MenuItem data-cy="front-desk-toggle" onClick={onEnable}>
      <StyledListItemIcon>
        <FontAwesomeIcon icon={faPhoneSlash} />
      </StyledListItemIcon>
      <ListItemText>Front Desk Mode</ListItemText>
    </MenuItem>
  );
}

function UserMenu({
  profile,
  currentRoles,
  passProviderStatusVisibility,
  currentlyOut,
}: UserMenuProps) {
  const { logout } = useAuthentication();
  const { removeFrontDeskMode } = useFlags();

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('Profile update failed:', error);
    },
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openProfileMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentRoleIds = currentRoles?.map((r) => r?.id) || [];
  const oneLifeUrl = process.env.REACT_APP_ONELIFE_URL;
  const currentRolesWithout = (toRemove: number) =>
    mapIdsToString(currentRoleIds.filter((id) => id !== toRemove));
  const isFrontDeskAdmin =
    currentRoleIds.includes(roles.ADMIN) && currentRoleIds.includes(roles.FRONT_DESK_ADMIN);
  const isProvider = currentRoleIds.includes(roles.PROVIDER);

  const canEnableFrontDeskMode = !removeFrontDeskMode && currentRoleIds.includes(roles.ADMIN);

  const { loading: loadingStatus, workSchedule } = useProviderStatus();

  if (loadingStatus) return <CircularProgress />;
  const isCurrentlyOut = () => {
    if (currentlyOut === null) {
      return workSchedule && workSchedule.returningOn !== null;
    }

    return currentlyOut;
  };
  return (
    <>
      <NavButton
        color="inherit"
        onClick={openProfileMenu}
        data-cy="user-menu-trigger"
        endIcon={<FontAwesomeIcon icon={faAngleDown} />}
      >
        {profile.displayName}
        {isCurrentlyOut() && <StyledIcon data-cy="ooo-icon" icon={faMinusCircle} color="#FFFFFF" />}
        {canEnableFrontDeskMode && isFrontDeskAdmin && (
          <StyledIcon icon={faPhoneSlash} color="#FBDD08" />
        )}
      </NavButton>
      <NavMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-cy="user-menu"
        disableScrollLock
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {canEnableFrontDeskMode && (
          <FrontDeskMenuItem
            isFrontDeskAdmin={isFrontDeskAdmin}
            onDisable={() =>
              updateProfile({
                variables: {
                  roleIds: currentRolesWithout(roles.FRONT_DESK_ADMIN),
                },
              })
            }
            onEnable={() =>
              updateProfile({
                variables: {
                  roleIds: mapIdsToString([...currentRoleIds, roles.FRONT_DESK_ADMIN]),
                },
              })
            }
          />
        )}
        {isProvider && (
          <MenuItem
            data-cy="change-status-button"
            onClick={() => {
              passProviderStatusVisibility(true);
            }}
          >
            Change My Status
          </MenuItem>
        )}
        <MenuItem component="a" href={`${oneLifeUrl}/admin/my_admin_account`} target="_self">
          My Account
        </MenuItem>
        <MenuItem
          onClick={() =>
            logout({
              logoutParams: { returnTo: `${process.env.REACT_APP_ONELIFE_URL}/auth/logout` },
            })
          }
        >
          Logout
        </MenuItem>
      </NavMenu>
    </>
  );
}

export default UserMenu;
