import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/utils';
import { AuthenticationProvider } from '@onemedical/auth';
import createAuthenticatedHttpLink from '@onemedical/graphql-auth';
import { v5Theme } from '@onemedical/react-theme';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app/App';

// TODO: import from @onemedical/rna/index.css once RNA supports this
import '@onemedical/rna/lib/cjs/index.cjs.css';

const LDProvider = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID!,
  options: { streaming: !window.Cypress },
})(({ children }) => children as React.ReactElement);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      // silence "Cache data may be lost when replacing the roles field of a
      // InternalUser object." development-time warning triggered by the
      // UpdateProfile mutation
      InternalUser: {
        fields: {
          roles: {
            merge(_, incoming: []) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  link: createAuthenticatedHttpLink({ uri: process.env.REACT_APP_GRAPHQL_API_URL }),
});

// namespace mui component css
ClassNameGenerator.configure((componentName) => `onelife-container-${componentName}`);

render(
  <BrowserRouter>
    <AuthenticationProvider
      auth0Domain={process.env.REACT_APP_AUTH_PROVIDER_URL!}
      auth0ClientId={process.env.REACT_APP_AUTH_CLIENT_ID!}
      auth0Connection={process.env.REACT_APP_AUTH0_CONNECTION}
      scope="openid profile email user/Procedure.read user/Patient.read user/Observation.read user/Condition.read"
      tokenStorage={window.Cypress ? 'localstorage' : 'memory'}
    >
      <LDProvider>
        <ApolloProvider client={client}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={v5Theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        </ApolloProvider>
      </LDProvider>
    </AuthenticationProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
